<template>
  <div class="page" style="background-color: #fff;">
    <Nav></Nav>
    <main class="wrap">
      <div class="main">
        <a-carousel autoplay arrows>
          <div slot="prevArrow" class="custom-slick-arrow" style="left: 10px; zindex: 1">
            <a-icon type="left-circle" />
          </div>
          <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
            <a-icon type="right-circle" />
          </div>
          <div v-for="(item, index) in banner" :key="index" :style="{ backgroundImage: 'url(' + item.filename + ')' }"
            class="silde-item" @click="bannerClick(item.url)"></div>
        </a-carousel>
        <div class="goods-list">
          <div class="goods" v-for="(item, index) in list" :key="index" @click="gogoods(item.id)">
            <img :src="item.pictures" alt="" class="cover" />
            <div class="title">{{ item.name }}</div>
            <div class="priceItem">
              <div class="price">
                ￥{{ item.price }}
              </div>
              <div class="marketprice">
                ￥{{ item.marketPrice }}
              </div>
            </div>
          </div>
        </div>
        <div class="list">
          <div class="list-item" v-for="(item, index) in icolist" :key="index" @click="goList(item.url)">
            <img :src="item.filename" alt="" class="cover" />
          </div>
        </div>
      </div>
    </main>
    <foot></foot>
  </div>
</template>

<script>

import Nav from "@/components/Nav.vue";
import foot from "@/components/foot.vue";

export default {
  name: "Home",
  components: {
    Nav,
    foot,
  },
  data() {
    return {
      banner: [],
      icolist: [],
      list: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    //请求数据
    loadData() {
      //banner广告
      this.api.getPosters({ id: 6, simple: false, }).then((res) => {
        this.banner = res;
      }).catch((err) => {
        console.log(err);
      });
      //banner广告
      this.api.getPosters({ id: 1007, simple: false, }).then((res) => {
        this.icolist = res;
      }).catch((err) => {
        console.log(err);
      });
      //商品列表
      this.api.getproducts({}).then(res => {
        this.list = res;
      }).catch(err => {
        console.log(err)
      });
    },
    bannerClick(url) {
      if (url) {
        this.$router.push(url);
      }
    },
    goList(url) {
      if (url) {
        window.open(url);
      }
    },
    gogoods(id) {
      this.$router.push("/Product?id=" + id);
    },
  },
};
</script>

<style scoped>
.page {
  background-color: #fff;
}

.ant-carousel>>>.slick-slide {
  text-align: center;
  height: 390px;
  overflow: hidden;
}

.silde-item {
  height: 390px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ant-carousel>>>.custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}

.ant-carousel>>>.custom-slick-arrow:before {
  display: none;
}

.ant-carousel>>>.custom-slick-arrow:hover {
  opacity: 0.5;
}

.main {
  min-height: 411px;
}

.main .list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 20px;
}

.main .list .list-item {
  cursor: pointer;
  object-fit: cover;
  width: 20%;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  overflow: hidden;
  min-height: calc(100% - 16px);
  text-align: center;
}

.main .list .list-item img {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
  padding: 10px;
}

.main .goods-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 50px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 411px;
  background-color: #f5f5f5;
}

.main .goods-list .goods {
  width: 24%;
  margin-bottom: 20px;
  background-color: #fff;
}

.main .goods-list .goods img {
  width: 256px;
  height: 256px;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
}

.main .goods-list .goods .title {
  font-size: 20px;
  font-weight: 600;
  padding: 5px;
  padding-left: 10px;
}

.main .goods-list .goods .priceItem {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.main .goods-list .goods .priceItem .price {
  font-size: 18px;
  color: #ff5717;
  padding-left: 10px;
}

.main .goods-list .goods .priceItem .marketprice {
  font-size: 16px;
  padding-left: 20px;
  color: #aaa;
  text-decoration: line-through;
}
</style>